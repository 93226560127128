import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import animationSlice from "./animationSlice"
import globalSlice from "./globalSlice"
import workSlice from "./workSlice"
import headerSlice from "./headerSlice"

const reducer = combineReducers({
  animationSlice,
  globalSlice,
  headerSlice,
  workSlice,
})
const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
})

export default store
