import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
// import "@popperjs/core/dist/umd/popper.min.js"
import wrapWithProvider from "./wrap-with-provider"

export const wrapRootElement = wrapWithProvider

export function onRouteUpdate({ location, prevLocation }) {
  window.scrollTo(0, 0)
}

export function shouldUpdateScroll() {
  window.scrollTo(0, 0)
  return [0, 0]
}
