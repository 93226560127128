import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isSkinnyWindow: false,
  isMenuOpen: false,
  isLoaded: false,
  isTouch: false,
}

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsSkinnyWindow: (state, action) => {
      state.isSkinnyWindow = action.payload
    },
    setIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload
    },
    setIsTouch: (state, action) => {
      state.isTouch = action.payload
    },
  },
})

export const { setIsSkinnyWindow, setIsMenuOpen, setIsLoaded, setIsTouch } =
  globalSlice.actions

export default globalSlice.reducer
