exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-templates-work-template-one-js": () => import("./../../../src/templates/workTemplateOne.js" /* webpackChunkName: "component---src-templates-work-template-one-js" */),
  "component---src-templates-work-template-three-js": () => import("./../../../src/templates/workTemplateThree.js" /* webpackChunkName: "component---src-templates-work-template-three-js" */),
  "component---src-templates-work-template-two-js": () => import("./../../../src/templates/workTemplateTwo.js" /* webpackChunkName: "component---src-templates-work-template-two-js" */)
}

