import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  workIndex: [],
  showTagMenu: false,
  tag: "All",
}

export const workSlice = createSlice({
  name: "work",
  initialState,
  reducers: {
    setWorkIndex: (state, action) => {
      state.workIndex = action.payload
    },
    setShowTagMenu: (state, action) => {
      state.showTagMenu = action.payload
    },
    setTag: (state, action) => {
      state.tag = action.payload
    },
  },
})

export default workSlice.reducer

export const { setWorkIndex, setShowTagMenu, setTag } = workSlice.actions
