import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  hideNav: false,
  prevScrollPos: 0,
  navTransparent: true,
  navTheme: "light",
}

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHideNav: (state, action) => {
      state.hideNav = action.payload
    },
    setPrevScrollPos: (state, action) => {
      state.prevScrollPos = action.payload
    },
    setNavTransparent: (state, action) => {
      state.navTransparent = action.payload
    },
    setNavTheme: (state, action) => {
      state.navTheme = action.payload
    },
  },
})

export default headerSlice.reducer

export const { setHideNav, setPrevScrollPos, setNavTransparent, setNavTheme } =
  headerSlice.actions
