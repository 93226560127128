import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  aboutFixed: false,
  homeFixed: true,
}

export const animationSlice = createSlice({
  name: "animation",
  initialState,
  reducers: {
    setAboutFixed: (state, action) => {
      state.aboutFixed = action.payload
    },
    setHomeFixed: (state, action) => {
      state.homeFixed = action.payload
    },
  },
})

export default animationSlice.reducer

export const { setAboutFixed, setHomeFixed } = animationSlice.actions
